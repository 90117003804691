import axios from 'axios';  


// ideal doc on configuring axios-instance: https://axios-http.com/docs/req_config 
export const AxiosInstance = axios.create({
    baseURL: 'https://vr-expo-server-dudov.cluster.ivnix.ru/api',
    withCredentials: false,
    redirect: 'follow',
});



const defaultParams = {
    limit: 10,
    page: 1,
    order: ['-id']
}
const defaultHeaders = {
    "Accept" : "application/json/text",
    "Access-Control-Allow-Origin" : "*",
} 

// geting all group`s students quests 
// 'params' - to searchAPI parametrs - to configure pagesize, pagenumber 
// 'formData' - data for form-like requests
async function API({url, 
                         requestOptions={},
                         formData=false,
                         params=defaultParams,
                         headers=defaultHeaders,
                         method='post'}) {
    let res
    try{ 
        if (formData) 
            res = await AxiosInstance[method](url, formData)
        else
            method !== 'get' ? 
                method === 'delete' ?
                res = await AxiosInstance[method](url,
                    {
                        headers: {
                            "Accept" : "application/json/text",
                            "Content-Type" : "application/json",
                        },
                        data: JSON.stringify(requestOptions),
                    }, 
                )
                :
                res = await AxiosInstance[method](url, 
                                                  requestOptions, 
                                                  {
                                                   headers: headers,
                                                   params: params,
                                                  })
            :
            res = await AxiosInstance['get'](url, {params: params, headers: headers})             // axios.get() has different signature( params are in 2nd arg) 
    }
    catch(err){
        if (err.response)
            {
                console.log('res:', JSON.stringify(err.response))
                //localStorage.setItem('ServerResp', err.response.error) // 'error message'
            }
        
        console.log('error', err.toJSON())
        
    }
    
    console.log('Hello from POST-based searchAPI:', res.data)

    // collect messages from all requests except GET and POST/search
    if (method!=='get' & url.indexOf('/search') === -1) 
        res.error && res.error !== 'undefined' ? 
            localStorage.setItem('ServerResp', res.error) // 'error message'
            : 
            localStorage.setItem('ServerResp', 'OK')        // no message <=> OK

    console.log('Now we`ve got serv`s message: ', localStorage.getItem('ServerResp'))
    return res.data // res.data = {data, meta} | meta - about paging and other stuff 
}



export async function getFiles(){
    return await API({url: `files`, method: 'get', params:{}})
}

// setModel - setState
export async function getModels({setModels=false}){
    const res = await API({url: `models`, method: 'get', params:{}})
    if (setModels) setModels(res)               
    return res
}

export async function findModels({ request = {name:'', tags:[]}, setModels=false}){
    const res = await API({url: `models/search`, method: 'post', requestOptions: request, params:{}})
    if (setModels) setModels(res)               
    return res
}



// setModel - setState
export async function getModelById({id, setModel}){
    const res = await API({url: `models/${id}`, method: 'get', params:{}})
    setModel(res)
    return res
}

export async function downloadModel(id){
    const res = await API({url: `models/${id}/download`, method: 'get', params:{}})
    return res
}



const getTagsCashed = () => {
      let cashed = localStorage.getItem("Tags_new_model")
      console.log('cashed tags for new model: ', localStorage.getItem("Tags_new_model"))
      // NEED ERRASE cashed info after return
      return (cashed && cashed !== "undefined") ? JSON.parse(cashed) : [] 
  } 

// CRUD methods:

// formDataArr - array of data with forms
export async function AddModel( {folderOpts, modelFilesOpts} ){
    
    
    // creating folder for model
    
    const addFolder = await API({url: 'models', formData: folderOpts, params: {}})
    console.log('resp:', addFolder)    // can help with relative path: parsing ID of model`s folder from response.link
    
    const model = addFolder.data
    const {name, description, specs, id} = model
    const tags = getTagsCashed()
    await ResetModelTags({name, description, specs, tags}, id) // set tags after had model`s id
    console.log('#### got tags: ', tags)

    //const folderID = addFolder.link.slice(addFolder.link.search('uploads') + 8, )   
    
    // transforming formData into backend format:
    
    // -----------------
    // in past it was need to send 2 request with 2 different formData to create valid model, now it`s unnecessary. but who knows?  
    
    // creating model   ID = addFolder.id  
    // await API({url: `models/${addFolder.data.id}`, formData: modelFilesOpts, params: {}})
}

// adds new format for model with id: id
export async function AddNewFormat({id, ModelOpts}){
    await API({url: `models/${id}`, formData: ModelOpts, params: {}})
}


export async function Modify(endpoint, options, id){
    return await API({url: `${endpoint}/${id}`, method: 'put', formData: options, params: {}})
}


export async function Del(endpoint, id){
    await API({url: `${endpoint}/${id}`, method: 'delete'})
}

export async function DelFormat(id, formats=[]){
    await API({url: `models/${id}/deleteFormats`, method: 'delete', requestOptions: {formats}})
}


export async function ResetModelTags(options, id){
    //const FormData_opts = new FormData()
    //for (let [key, value] of Object.entries(options))
    //    FormData_opts.append(key, value)

    return await API({url: `models/${id}`, method: 'put', requestOptions: options, params: {}})
}

export async function Auth(){
    const token = 'await for some function to get auth token'
    localStorage.setItem('AuthToken', token)
}



export async function Reg(){
    
}