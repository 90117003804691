import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/app/app';  // old one
import { Container } from './shitty-lib/ModelLowerLvl/src'; // new model


const rootElement = document.getElementById("root");
ReactDOM.render(
  <App />,
  rootElement
);
