import { Popover, Typography } from "@mui/material"
import { useState } from "react"
import { RowContainer } from "../shitty-lib"
import { key_gen } from "../shitty-lib/hashfunc"
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useLocation, useNavigate } from "react-router-dom";
import '../shitty-lib/noScroll.css'

// popping bigger card after click
const PopEl = ({Content=<div/>, setOpen, open=false}) => <div>
  <Popover 
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: 'center', horizontal: 'center' }}
        
      >
        {Content}
  </Popover>
 </div>


// usage: style ={{ ...hoverStyle( {BG,color} ) }}
export const hoverStyle = ({BG = 'rgba(0,0,0, 0.1)', color = 'primary'}) =>  ( {
  "&:hover": {background: BG, color: color}, 
  borderRadius: '7px'
}) 


// in case we have many divs with onClick() nested => this stops all other divs from firing event 
const stopFiring = (e) => {
  if (!e) var e = window.event;
  e.cancelBubble = true;
  if (e.stopPropagation) e.stopPropagation();
}

// content of card of volatile size: wh
const DataCard = ({
  data={name: '', description: '-'},
  Content=null,
  wh={w: '20rem', h: '9rem'},
  interactive=true,
  onClick = false,
  setCardOpen=()=>{},
  setDelOpen=()=>{},
  setModOpen=()=>{},
  setChosenRow=()=>{} 
}) => <div 
    style={{height: wh.h, width: wh.w, display: 'flex', flexDirection: 'column',
           justifyContent: 'start', cursor: 'pointer', overflow: 'hidden'}}
    onClick={onClick ? onClick : () => setCardOpen(true)}>
         
        <RowContainer style={{ justifyContent: 'start'}}>
          
          {interactive ? <EditOutlinedIcon 
            onClick={(e) => {setModOpen(true); setChosenRow(data); stopFiring(e)}}
            sx={{ marginLeft: '1rem', ...hoverStyle({BG: 'rgba(0,0,0, 0.1)'}) }}
            /> : null}  
        </RowContainer> 
        
        
        <RowContainer style={{ justifyContent: 'space-between', height: '75%'}}>
          <Typography variant="content" textAlign="start" height='100%' width='100%'>
             {Content !== null ? Content : data.description}    
          </Typography>
          
          {interactive ? <DeleteForeverOutlinedIcon 
            onClick={(e) => {setDelOpen(true); setChosenRow(data); stopFiring(e)}}
            sx={{ marginRight: '0.3rem', alignSelf: 'end', ...hoverStyle({BG: 'rgba(255,0,0, 0.1)'})}}/> : null}
        </RowContainer>
        <Typography variant="content" textAlign="start" sx={{ paddingLeft: '0.5rem', height: '2rem', border: '2px solid #E1E1E1', borderRadius: '10px', borderTop: '0px', borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
            {data.name}
          </Typography> 
</div>


export const DataCardWithImage = ({
  data={name: '', description: '-', id:'someID', img:'no photo'},
  onClick=()=>{}, card_wh={w: '20rem', h: '9rem'}
}) => {
  
  // 1920 / 1080 ~ 1,77 => 177% width 
  const Content = <img
  style={{overflow: 'hidden', borderRadius: '10px', borderBottomLeftRadius: 0, borderBottomRightRadius:0, border: '2px solid #E1E1E1'}} 
  src={data.img} 
  height={'100%'} width={'100%'}
  onClick={onClick}
  />

  return <DataCard wh={card_wh} data={data} Content={Content} interactive={false}/>
}



// Manager-Component - ready to use
// OP_wh - wh when card is open
export const InteractiveDataCard = ({
  data, OP_wh={w: '60rem', h: '35rem'}, 
  setDelOpen, setModOpen, setChosenRow, onClick = false}
) => {
  const [open, setOpen] = useState(false) 
  return <div>
    <DataCard 
      key={key_gen(data, 'card')}
      data={data}
      setCardOpen={setOpen}
      setDelOpen={setDelOpen}
      setModOpen={setModOpen}
      setChosenRow={setChosenRow}
      onClick={onClick}
      />
    <PopEl
      Content={
        <DataCard 
        interactive={false}
        data={data} 
        wh={OP_wh}
        key={key_gen(data, 'card_info')}
        />
      }
      open={open}
      setOpen={setOpen}/>
  </div>
  
}




export const CardsAreaWrapper = ({style, children}) => <RowContainer 
  className="noscroll"
  style={{ justifyContent: 'start', gap: '0.7rem', flexWrap: 'wrap', overflow: 'scroll', alignContent: 'start', ...style }}>
    {children}
   </RowContainer>