
import { useEffect, useReducer, useRef, useState } from 'react';
import {GLTFModel, MTLModel, DAEModel, DirectionLight, AmbientLight} from 'react-3d-viewer'
import { CircleDots } from '../Spinners';
import { getFiles, Modify } from '../Utils/requests';


export const remToPx = (rem) => rem * parseFloat(getComputedStyle(document.documentElement).fontSize)

export const percToPx = (perc, isWidth=true) => {
  return Math.round(( perc/100 ) * (isWidth ? window.screen.width : window.screen.height) )
} 



// '../media/baseball/baseball_01_4k.gltf'
// https://raw.githubusercontent.com/dwqdaiwenqi/react-3d-viewer/master/site/src/lib/model/DamagedHelmet.gltf

// light - hex value of light`s color
// WH`s poles - in %.
// orginal wh - in px
// - so we must so use converter: % -> px  
export const CoolViewer = ({chosen={}, textureIdx=0, src='', light=0xffffff, WH={width:98, height:27}, color='white',isFullscreen}) => {
 const defaultPos = {x:0,y:-40,z:0}
 const defaultPosN = {x:0,y:0,z:0} // {x:0,y:-100,z:0}
 const mtlRot = {x:0,y:10,z:0}    // obj + mtl has front side wall by default => rotate to see model at spawn

 const [wh, setWh] = useState({width:percToPx(WH.width), height:percToPx(WH.height)})
 const handleResize = () => setWh({ width: percToPx(WH.width), height: percToPx(WH.height)})

 const [chosenModel, setChosenModel] = useState(chosen)
 const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

 /* cashing staff
 if (chosenModel !== chosen) {setChosenModel(chosen)}
 if (chosenModel.addons_info) console.log('3DViewer chosen model: ', chosenModel, `\nwith texture[${textureIdx}]: `, chosenModel.addons_info[textureIdx])
 else console.log('src is:', src)
 */

 const ref = useRef(null)


 // wait till DOM build
  useEffect(()=>{
    // when size/scale of window changes
    window.addEventListener("resize", handleResize)

    console.log(`ref got scene: ${ref.current}`, ref.current.scene) 
    ref.current.scene.scale.set(0.5, 0.5, 0.5)  // scaling
  })


  const getTextureIdxCashed = () => {
    let cashed = localStorage.getItem("TextureIdx")
    console.log('cashed texture: ', localStorage.getItem("TextureIdx"))
    return (cashed && cashed !== "undefined") ? JSON.parse(cashed) : 0 
  } 

  const cashedTextureIdx = getTextureIdxCashed()

  
  // path always starts with "." and thr next "." is extension
  const i = src.slice(1, src.length).indexOf('.')
  




  if (src==='') {
    
    const Viewer = () => <MTLModel 
    key={'uniq_ID_4_3dViewer11322'}
    ref={ref}
    id={'uniq_ID_4_3dViewer11322'}
    mtl={chosenModel.preview.colours[cashedTextureIdx] !== undefined ? chosenModel.preview.colours[cashedTextureIdx].link : chosenModel.preview.colours[0].link} 
    src={chosenModel.preview.model_link}
    texPath="https://vr-expo-server-dudov.cluster.ivnix.ru/storage/uploads/6841ea6491933c8ba4b3009c929ab845/preview/Car-Model"
    background={color} 
    width={wh.width} height={!isFullscreen ? wh.height : remToPx(56)} 
    position={defaultPosN} rotation={mtlRot}> 
    <AmbientLight color={0xffffff}/>

    <DirectionLight color={light} position={{x:700,y:0,z:700}}/>

    <DirectionLight position={{x:0,y:0,z:0}}/>
    <DirectionLight position={{x:7000,y:0,z:0}}/>
    <DirectionLight position={{x:0,y:7000,z:0}}/>
    <DirectionLight position={{x:0,y:0,z:7000}}/>
    <DirectionLight position={{x:7000,y:7000,z:7000}}/>

    </MTLModel>
    

    return chosenModel.preview.model_link ? <Viewer/> : null} 

  else
  switch (src.slice(i+2, src.length)) {
    case 'gltf':
      return <GLTFModel src={src} background={'white'} position={defaultPos}
      width={wh.width} height={wh.height}>
         <AmbientLight color={0xffffff}/>
         <DirectionLight color={light} position={{x:200,y:-200,z:0}}/>
      </GLTFModel>

    case 'mtl':
      return <MTLModel mtl={src} src={src.replace('.mtl', '.obj')} 
      width={wh.width} height={!isFullscreen ? wh.height : remToPx(56)} 
      position={defaultPos} rotation={mtlRot}> 
         <AmbientLight color={0xffffff}/>

         <DirectionLight color={light} position={{x:700,y:0,z:700}}/>

         <DirectionLight position={{x:0,y:0,z:0}}/>
         <DirectionLight position={{x:7000,y:0,z:0}}/>
         <DirectionLight position={{x:0,y:7000,z:0}}/>
         <DirectionLight position={{x:0,y:0,z:7000}}/>
         <DirectionLight position={{x:7000,y:7000,z:7000}}/>

      </MTLModel>

    case 'dae':
      return <DAEModel src={src}
      width={wh.width} height={wh.height} position={defaultPos}>
         <AmbientLight color={0xffffff}/>
         <DirectionLight color={light} position={{x:200,y:-200,z:0}}/>
      </DAEModel>

    default:
      console.log('this format is unavaliable to display: ', src.slice(i+2, src.length), ' i = ', i)
  }
  
}

