/*
    Here we have configuration for dirty-made parts:
     1) categories and tags
     2) creditionals and token 
*/


// 1) categories and tags are same but categories have section=null

const tags = [
// ------- categories:
[null, 'Материалы'],
[null, 'Декор'], 
[null, 'Мебель'], 
[null, 'Техника'], 
[null, 'Капитальные эл-ты'],

// ------- tags:
['Материалы', 'металл'], 
['Материалы', 'дерево'],
['Материалы', 'пластик'],
['Материалы', 'резина'],
['Материалы', 'стекло'],
['Материалы', 'камень'], 
['Материалы', 'жидкость'],

['Декор', 'ваза'], 
['Декор', 'картина'],
['Декор', 'прегородка'],

['Мебель', 'стол'], 
['Мебель', 'стул'], 
['Мебель', 'дверь'], 
['Мебель', 'шкаф'], 
['Мебель', 'кресло'], 

['Техника', 'fridge'], 

['Капитальные эл-ты', 'окно'],
['Капитальные эл-ты', 'прегородка'],
['Капитальные эл-ты', 'стена'], 
]

export const avalibleTags = tags.map( (tag) => { return {
    section: tag[0], 
    name: tag[1]
} 
})


// another dirty KOSTYL` - get array of tag-arrays grouped by sections
export const get_sections = () => {
    let sections = avalibleTags.map((el)=>{
        

        if (!el.section) return 'nope'
        return [avalibleTags.filter((EL) => el.section === EL.section && EL.section !== null && el.section !== null), el.section] 
      })

    
    // ---------- very dirty part ----
    sections = sections.filter(x=>x!=='nope')
    const buff = []
    let sections_uniq = []
    
    for (let el of sections)
      if (( !buff.includes(el[1]))) {
        buff.push(el[1])
        sections_uniq.push(el)
      }
      else 
        continue    

    console.log('**** got sections', sections_uniq)
    return sections_uniq
}
