import DownloadIcon from '@mui/icons-material/Download';
import { Typography } from '@mui/material';
import { ColumnContainer, RowContainer } from '../shitty-lib';
import WbIncandescentTwoToneIcon from '@mui/icons-material/WbIncandescentTwoTone';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import { downloadModel } from '../Utils/requests';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';


const Border = ({ margin='2px'}) => {
    return <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.09)',
    margin: margin, width: '100%' }} />
}


const ToggleFormat = ({style}) => {
    return <ColumnContainer style={{
     border: '1px solid rgba(0, 0, 0, 0.09)', borderRadius: '8px',
     justifyContent: 'space-evenly', 
     padding: '3px', cursor: 'pointer', height: '100%', ...style}}>
    <Typography variant='softTitle' sx={{"&:hover": {color: '#0C61F8'}}}>
        gltf
    </Typography>  
    <Border  margin='0px'/>
    <Typography variant='softTitle' sx={{"&:hover": {color: '#0C61F8'}}}>
        mtl
    </Typography>
    
  </ColumnContainer>
}


async function awaitUrlSet({id='', setLink = ()=>{}}){
    const link = await downloadModel(id)
    console.log('got link: ', link)
    setLink(link.model_archive_link)
}

export const OptionsBar = ({setLightColor, lightColor, isFullscreen, setFullScreen, chosen}) => {

    const path = useLocation().pathname
    const i = path.lastIndexOf('/') + 1
    const id = path.slice(i,)
    //const [downloadLink, setDownloadLink] = useState('')

    //useEffect(() => {awaitUrlSet({id, setLink: setDownloadLink})}, [])

    return <div style={{display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'end', 
    borderLeft: '1px solid rgba(0, 0, 0, 0.09)', background: 'white', height: !isFullscreen ? '27rem' : '56rem'}}>

        <div style={{ height: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
          <ColumnContainer style={{rowGap: 0, alignItems: 'center', paddingBottom: '2rem'}}> 
            <WbIncandescentTwoToneIcon sx={{color: '#' + lightColor.toString(16) }}/>  
            <input type="color" id="color-picker" key="color-picker" 
            value={'#' + lightColor.toString(16)} 
            onChange={(e)=>setLightColor( parseInt(e.target.value.slice(1,), 16) )}
            
            style={{border: '1px solid rgba(0, 0, 0, 0.09)', borderRadius: '10px', 
            background: 'white', height: '30px', width: '30px'}}
            />
          </ColumnContainer>
          
          <div>
            {!isFullscreen ? 
            <FullscreenOutlinedIcon sx={{cursor: 'pointer'}} onClick={() => setFullScreen(true)}/> : 
            <FullscreenExitOutlinedIcon sx={{cursor: 'pointer'}} onClick={() => setFullScreen(false)}/>}
          </div>
        </div>


    </div>

}