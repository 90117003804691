
import { useNavigate } from 'react-router-dom';


import { Link,
         AppBar,
         Box,
         Toolbar,
         IconButton,
         Typography,
         Menu, 
         Avatar,
         Tooltip,
         MenuItem,
         Popover,
        } from "@mui/material"
import React, { useState } from 'react';
import { Iconify } from '../shitty-lib';



const pages = [['Модели', '/admin']];
const settings = ['Редактирование', 'Выйти'];

export const Head = () => {
  const redirect = useNavigate()  
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [open, setOpen] = useState(false)
  const [modOpen, setModOpen] = useState(false)

  const handleOpenUserMenu = (event) => {
    //setAnchorElUser(event.currentTarget);
    redirect('/admin')
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  
  return ( <AppBar position="sticky" sx={{zIndex: 10000, background: 'linear-gradient(0deg, rgba(0,25,89,0.9177871832326681) 42%, rgba(0,15,67,1) 97%)'}}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '5px', paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>
        
        <img src={process.env.PUBLIC_URL + '/media/logo3D.svg'} width={'50px'} height={'50px'} style={{marginRight: '2rem', cursor: 'pointer', flexGrow: 0}}
             onClick={()=>redirect('/')} />
        
          <Typography
            variant="title"
            noWrap
            component="a"
            onClick={()=>redirect('/')}
            sx={{
              pl: '0rem',
              pr: '4rem',
              cursor: 'pointer',
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
            }}>
           VR-expo
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex', } }}>
              {pages.map(([page, link]) => (
                <MenuItem key={page} >
                  <Link onClick={() => redirect(link)} color='inherit' underline='none'>
                    <Typography textAlign="center" variant='softSubtitle'> {page}</Typography>
                  </Link>
                </MenuItem>
              ))}
          </Box>


          <Box sx={{ flexGrow: 0,}}>
            <Tooltip title="Редактирование моделей">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Admin" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            

            </Box>
      </Box>
    </AppBar>
  );
}

