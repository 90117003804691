import { Button, Typography } from "@mui/material"
import { useState } from "react"
import { Head } from "../components/Header"
import { CoolViewer } from "../components/Viewer3D"
import { ColumnContainer, RowContainer } from "../shitty-lib"
import {MTLModel, DirectionLight, AmbientLight} from 'react-3d-viewer'
import { remToPx } from "../components/Viewer3D"


// https://www.youtube.com/watch?v=TTpbP5BVtiA
// 
export const PublicPageGreet = () => {
    const light = '0xffffff'
    const wh = {width: remToPx(40), height: remToPx(35)}
    const defaultPos = {x:0,y:-20,z:0}
    const mtlRot = {x:0,y:10,z:0}    // obj + mtl has front side wall by default => rotate to see model at spawn
    const src = './media/showcase/showcase.mtl' // './media/showcase/showcase.mtl'

    const background = 'https://media4.giphy.com/media/QWoqJ8PAslb26pLnXk/giphy.gif?cid=ecf05e47nyi7nvexvkc8acg99355hjjx0qvi92fg9a23gqjy&ep=v1_gifs_search&rid=giphy.gif&ct=g'

    const videoLink = 'https://www.youtube.com/watch?v=TTpbP5BVtiA'

    return <ColumnContainer style={{ height: '100vh'}}>

    <ColumnContainer style={{gap: '0',width:'100%', height:'100%', justifyContent: 'start',  zIndex: 5, backgroundImage: `not url(${background})`}}>
    <Head/>
    <Typography variant="title"> 'Greeting and Killer feachure. Every seener now`ve got an addiction to this page' </Typography>
   
    <div style={{display: 'flex', justifyContent: 'center'}}>
     <MTLModel mtl={src} src={src.replace('.mtl', '.obj')} 
      width={wh.width} height={wh.height} background={'rgba(255, 150, 100)'} 
      position={defaultPos} rotation={mtlRot}> 
         <AmbientLight color={0xffffff}/>

         <DirectionLight color={light} position={{x:700,y:0,z:700}}/>

         <DirectionLight position={{x:0,y:0,z:0}}/>
         <DirectionLight position={{x:7000,y:0,z:0}}/>
         <DirectionLight position={{x:0,y:7000,z:0}}/>
         <DirectionLight position={{x:0,y:0,z:7000}}/>
         <DirectionLight position={{x:7000,y:7000,z:7000}}/>

      </MTLModel>
      </div>
      
    <RowContainer style={{gap: '10px'}}>
    <Button variant="outlined" sx={{borderRadius: '0px'}} href='/admin'>
        Каталог моделей
    </Button>

    <Button variant="outlined" sx={{borderRadius: '0px'}} href='/contacts'>
        Связаться с нами
    </Button>

    </RowContainer>

    </ColumnContainer>

    <video autoPlay loop muted style={{position: 'absolute', overflow: 'hidden', zIndex: 1, width:'100%', height:'100%'}}>
    <source  src={`/media/videos/Pigeon.mp4`} type='video/mp4' />
    </video>

</ColumnContainer>
}