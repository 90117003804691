import { Tab, Tabs, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { useState } from "react"
import { ColumnContainer, RowContainer } from "../shitty-lib"

const BoxEl = ({
  el={name: 'fake_name'}, 
  isChosen, onClick
}) => {
  return <button 
    onClick={onClick}
    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', 
    minWidth: '7rem', maxHeight: '1.5rem', minHeight: '2rem', 
    border: '1px solid #2D9CDB', borderRadius: '5px',
    cursor: 'pointer',
    background: !isChosen ? 'white' : '#2D9CDB'}}>
     <Typography variant="content" textAlign="center" >
    {el.name}
  </Typography>
  </button>
}

// Todo add toggling for info:
// - caption, formats,  
export const ModelDetails = ({chosen, setTextureIdx, textureIdx, justTextures=false}) => {

    const no_addons = chosen.preview === undefined || chosen.preview.colours === undefined ? 'no addons' : false

    const tab_els = [{name:'Описание', id: 'dadgs141dajfgijsd2414p3io86gjs', content: <Typography variant="content" textAlign='start'>{chosen.description}</Typography> || ''}, 
    {name:'Доступные текстуры', id: 'eler0dyt98nvkew142ad41pjyp16jot8p', 
    content: no_addons ? no_addons : 
      chosen.preview.colours.map((el, i) => <BoxEl el={el} isChosen={i===textureIdx} onClick={()=>{setTextureIdx(i); window.location.reload()}}/>) || ''}]

    const SetValue = (val) => localStorage.setItem("ModelBarKey", val)
    const GetValue = () => JSON.parse(localStorage.getItem("ModelBarKey"))
    if ( !localStorage.getItem("ModelBarKey") ) SetValue(0)
    
    const [val, setVal] = useState(GetValue())

    const handleClick = (el) => { 
      
    }

    const handleChange = (e, newValue) => {SetValue(newValue); setVal(newValue)}


    if (justTextures)
      return <ColumnContainer className='noscroll'>
        <ContentArea content={tab_els[1].content} style={{ border: '0px', rowGap: '0.25rem', padding: 0, margin: 0, width: '100%' }}/>
      </ColumnContainer>
    else

      return <Stack style={{alignItems: 'start', justifyContent: 'start', height: '28rem', flexWrap: 'nowrap',}}>
        <Tabs
          value={val}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          indicatorColor='primary'
          textColor="primary">
          { tab_els.map((el) => <Tab 
            key={el.id} 
            className='noscroll'
            style={{height: '1vh', width: '35vh', overflow: 'elipsis', display: 'flex', justifyContent: 'center'}} 
            label={ 
              <Typography variant="subtitle"> {el.name} </Typography>
            } 
            />)}
      </Tabs>
          
          <ContentArea content={tab_els[GetValue()].content}/>
      </Stack>
} 



const ContentArea = ({content, style}) => {

  return <ColumnContainer style={{ border: '1px solid rgba(0,0,0,0.1)', height: '100%', width: '70%', 
  flexWrap: 'nowrap', justifyContent: 'start', padding: '1rem', paddingTop: '0.5rem', ...style}}>
      {content}
  </ColumnContainer>
}