import { Button, Input, MenuItem, Paper, Popover, Popper, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { Head } from "../components/Header"
import { ModelsBar } from "../components/ModelsBar"
import { ColumnContainer, Iconify, RowContainer } from "../shitty-lib"
import { AddModal, DelModal, ModifyModal } from "../shitty-lib/modals"
import { getFiles, getModels } from "../Utils/requests"

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { CardsAreaWrapper, DataCardWithImage, InteractiveDataCard } from "../components/InteractiveCard"
import { FilesCRUDArea } from "../components/filesCRUDArea"
import { FileUploader } from "react-drag-drop-files"
import { DrangAndDrop } from "../components/dragAndDrop"
import { useNavigate } from "react-router-dom"
import { TagsModalContentEnhanced } from "./admin-page-mod"

import '../shitty-lib/noScroll.css'
import { LeftBar } from "../components/LeftBar"
import { ControlAuth } from "./login-page"

// -------------- Configurating modals ------
// poles and their inputs
// add
const poles={
    to_fill: [
    {
      name: 'name', 
      placeholder: 'Наименование модели',
      InputElement: (props) => <TextField {...props}/> 
    },
    {
      name: 'description', 
      placeholder: 'Описание',
      InputElement: (props) => <TextField multiline minRows={5} {...props}/> 
    },
    {
      name: 'specs', 
      placeholder: 'Характеристики',
      InputElement: (props) => <TextField {...props}/> 
    },
    {
      input_type: 'files', // 
      name: 'img',  
      InputElement: (props) => <DrangAndDrop 
      placeholder = {'Изображение модели'} 
      srtyle={{justifySelf: 'row'}}
      {...{...props, types:['jpg', 'jpeg', 'png']}}/>
      
    },
    {
      input_type: 'files', // 
      name: 'zip',  
      InputElement: (props) => <DrangAndDrop 
      placeholder = {'Файл модели'} 
      style={{justifySelf: 'row', marginLeft: '25rem', marginTop: '-9.9rem'}}
      {...{...props, types:['zip', '7z', 'rar']}}/>
      
    },
    ],
    
    readyData: {
      

    }
}

export const modify_poles = {...poles, to_fill: poles.to_fill.slice(0,3)}


// forms in modal: formdataArr
const formDataFolder = new FormData();
const formDataModel = new FormData();
const modFormData = new FormData()

const formDataArr = [
  {
    formData: formDataFolder, 
    needPoles: ['name', 'description', 'specs', 'img', 'zip']
  },

  // -----------------
  // in past it  need to send 2 request with 2 different formData to create valid model, now it`s unnecessary. but who knows?  
  // now it`s still "alive" not ruin AddModal in modals.js (if u want to "kill" it, u can remove 'modelFilesOpts' in AddModals) 
  {
    formData: formDataModel, 
    needPoles: ['i dont know name of archive pole yet']
  },
]




const formDataArrMod = [
  {
    formData: modFormData, 
    needPoles: ['name', 'description']
  }, 
]



const dummyModels={data:[
  {description:'bruh1', name:'Стойка(dummy)', id: 'dummy46sdiuhfus12'},
  {description:'bruh2', name:'Шкаф(dummy)',   id: 'dummy203902hgdfb2'},
  {description:'bruh3', name:'Стелаж(dummy)', id: 'dummy03295tgfe902'}
],
meta: {test: 'test'}}



const AddModalContent = () => <ColumnContainer style={{rowGap: 0}}>
  <Typography variant="content" textAlign='start'>
    Тэги модели:
  </Typography>
  
  <div className='noscroll' style={{height: '11.5rem', paddingTop: '0.5rem', overflow: 'scroll'}}><TagsModalContentEnhanced/></div>
</ColumnContainer>


const AdminPageContent = () => {
  const [models, setModels] = useState({
    data:[],
    meta: {test: 'there`ll be pagination info'}
  })

  const [addOpen, setAddOpen] = useState(false)

  const refreshData = () => getModels({ setModels: (data) => setModels({...models, ...data})  })
 
  useEffect(()=>{
    refreshData()
    console.log('Models: ', models.data)
  }, [])

  const redirect = useNavigate() 


  const vanishFormData = () => formDataArr[0].needPoles.map((name) => formDataArr[0].formData.delete(name)) 

  const Content = () => <ColumnContainer className="noscroll" style={{ width: '100%', height: '100%', overflow: 'hidden', alignItems: 'start', paddingLeft: '0.8rem', gap: '0px', rowGap: '0px'}}>
  <RowContainer style={{ gap: '0px', rowGap: '0px', width: '100%', height: '3rem', alignItems: 'end', justifyContent: 'start', paddingLeft: '0rem'}}>
    <Typography variant="bigTitle" padding='0.5rem'> Все модели </Typography>
    <Button variant="outlined" sx={{ml:'3rem', }}
    onClick={() => setAddOpen(true)}>
    <AddTwoToneIcon/>  
    
    </Button>
  </RowContainer>
  
  <CardsAreaWrapper className="noscroll" style={{ marginTop: '1rem', overflow: 'scroll', height: '93vh',}}>
    { 
      models.data.map((model) => <DataCardWithImage card_wh={{w:'18.5rem', h:'18.7rem'}}
        data={{...model, img: model.preview.image_link ? model.preview.image_link: './media/Millimetrovka.png'}}
        onClick={ ()=>redirect(`/admin-mod/${model.id}`) }
        />
      )}

  </CardsAreaWrapper>

  <AddModal isOpen={addOpen} vanishFormData={vanishFormData} setOpen={setAddOpen} is_multiple={false} 
  Content={AddModalContent}
  endpoint='models' refreshData={refreshData} formDataArr={formDataArr} poles={poles}/>
    
  
</ColumnContainer>

  return <RowContainer style={{ alignItems: 'start', width:'100%', height: '100%', gap: 0, rowGap: 0, padding: 0 }}>
    <LeftBar setModels={setModels} models={models.data}/>
    <Content/>
  </RowContainer>
}



export const AdminPage = () => {
  
  //useEffect(()=>{
  //  ControlAuth() // kick anauthorized user to login page
  //},[])

  return <div style={{ alignItems: 'start', width: '100%' }}>
       
          <AdminPageContent/>
      
  </div>
}