
import { ColumnContainer, RowContainer } from "../shitty-lib"
import { ClearOutlined, DeleteForever } from "@mui/icons-material"
import { Button, Typography } from "@mui/material"
import {hoverStyle} from '../components/InteractiveCard'
import { gen_color_palette } from "./gen-pallete"



// Hrefable data presented as train - blocks in a multiple rows that can be clicked
export const TrainLike = ({caption='', nameLinkArr, onDelClick=({name})=>{}}) => {

    if (!nameLinkArr) 
        return null
    
    return <RowContainer 
      style={{ rowGap: '0.7rem', gap: '0.7rem', flexWrap: 'wrap', justifyContent: 'start'}}>
        <Typography textAlign='start'>{caption}:</Typography> 
        {nameLinkArr.map((el) => { 
        const {name, link, section} = el
        const color = gen_color_palette([{name, section}], 0.8)
        const hover_color = gen_color_palette([{name, section}], 0.3)['backGround']
        const emptyTag = !link && !section && name

        return emptyTag ? null : <div>
        <Button href={ link ?? link} variant={"outlined"}

        sx={ !link ? {
            ...hoverStyle({BG: hover_color}),
            color: color['border'], 
            border: `1px solid ${color['border']}`,
            } : {} }>
          <Typography> {name} </Typography>
        </Button>
        <ClearOutlined 
          sx={{cursor: 'pointer', width: '1.2rem', ...hoverStyle({BG: 'rgba(255,0,0, 0.1)'})}} 
          onClick={(e) => { !link ? onDelClick(e, el) : onDelClick(e, name)}}/>
        </div>} )}

    </RowContainer>}


export const was_chosen = (tag, chosenTags) => chosenTags.filter(Tag => Tag.name === tag.name && Tag.section === tag.section).length !== 0

// train without delete button
export const TrainWB = ({caption='', style, chosenTags, nameLinkArr, onClick=({name})=>{}}) => <RowContainer 
      style={{ rowGap: '0.7rem', gap: '0.7rem', flexWrap: 'wrap', justifyContent: 'start', ...style}}>
        <Typography textAlign='start'>{caption}:</Typography> 
        {nameLinkArr.map((el) => { 
        const {name, section} = el
        const emptyTag = !section && name

        //const was_chosen = (tag) => chosenTags.filter(Tag => Tag.name === tag.name && Tag.section === tag.section).length !== 0
        //console.log('$$$$ was_chosen? ', nameLinkArr[0], was_chosen(nameLinkArr[0]))


        const color = gen_color_palette([{name, section}], 0.8)
        const hover_color = gen_color_palette([{name, section}], 0.3)['backGround']

        return emptyTag ? null : <div>
        
        <Button variant={"outlined"}
          onClick={(e) => onClick(e, el)}
          sx={{
            ...hoverStyle({BG: hover_color}),
            color: color['border'],
            background: was_chosen(el, chosenTags) ? color['backGround']:'transperent', 
            border: `1px solid ${color['border']}`,
            }}>
          <Typography> {name} </Typography>
        </Button>

        </div>} )}

    </RowContainer>