
import { TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Head } from "../components/Header"
import { ModelDetails } from "../components/modelDetails"
import { ModelsBar } from "../components/ModelsBar"
import { OptionsBar } from "../components/OptionsBar"
import { CoolViewer } from "../components/Viewer3D"
import { ColumnContainer, RowContainer } from "../shitty-lib"
import { AddModal } from "../shitty-lib/modals"
import { getModels } from "../Utils/requests"

/*
    From git: https://github.com/kovacsv/Online3DViewerExamples/tree/main/use_as_react_component
        <Basic3DViewer file='../../media/skuller/Skull.obj'/>

    ./media/baseball/baseball_01_4k.gltf
    ./media/showcase/showcase.mtl
    ./media/vase/vase.mtl
    ./media/plant/plant.dae
*/


const PublicPageContent = ({chosen, textureIdx=0, setTextureIdx}) => {
    const [lightColor, setLightColor] = useState(0xFFA348)
    // toggling fullscreen view of model
    const [isFullscreen, setFullScreen] = useState(false)

    return <ColumnContainer style={{justifyContent: 'start', rowGap: 0, height: 'calc(100vh - 64px)',}}>
    <div style={{display: 'flex', justifyContent: 'start', height: '27rem'}}>
    
     {chosen.preview ? <CoolViewer
        chosen={chosen} textureIdx={textureIdx} light={lightColor}
        isFullscreen={isFullscreen} setFullScreen={setFullScreen}/> : 'nope'}        

        <OptionsBar 
        chosen={chosen}
        lightColor={lightColor} setLightColor={setLightColor} 
        isFullscreen={isFullscreen} setFullScreen={setFullScreen}
        />
    </div>
    { !isFullscreen ? <ModelDetails textureIdx={textureIdx} setTextureIdx={setTextureIdx} chosen={chosen}/> : null }
    </ColumnContainer>
    
  }
  



export const PublicPageView = () => {

  const [models, setModels] = useState({
    data:[{addons_info: [], model_info: 'empty_path'}],
    meta: {test: 'there`ll be pagination info'}
  })


  const setTextureIdxCashed = (x) => localStorage.setItem("TextureIdx", JSON.stringify(x))
  const getTextureIdxCashed = () => {
      let cashed = localStorage.getItem("TextureIdx")
      console.log('cashed texture: ', localStorage.getItem("TextureIdx"))
      return (cashed && cashed !== "undefined") ? JSON.parse(cashed) : 0 
  } 


  useEffect(()=>{
    getModels({ setModels: (data) => setModels({...models, ...data})  })
  }, [])



  // init default state 
  // check if there`s cashed which was chosen previously
  const setChosenCashed = (x) => localStorage.setItem("chosenModel", JSON.stringify(x))
  const getChosenCashed = () => {
      let cashed = localStorage.getItem("chosenModel")
      console.log('cashed Model: ', localStorage.getItem("chosenModel"))
      const readyData = models.data.length > 0 ? models.data[0] : false 
      return (cashed && cashed !== "undefined") ? JSON.parse(cashed) : readyData 
  } 

  const cashedChosen = getChosenCashed() 
  const cashedTexture = getTextureIdxCashed()

  // chosen from useState need for rerender (by useEffect)
  const [chosen, setChosen] = useState( cashedChosen ? cashedChosen : models[0])
  const [textureIdx, setTextureIdx] = useState( cashedTexture ? cashedTexture : 0)

  const [update, setUpdate] = useState(0);
  const refresh = () => setUpdate(update + 1);


  const redirect = useNavigate() 
  const i = useLocation().pathname.search('public-view/') + 'public-view/'.length
  const ID = useLocation().pathname.slice(i,)

  const [chosen_model, setChosen_model] = useState(models.data[0])

  useEffect(()=>{
    setChosen_model(
      models.data[0].model_info!=='empty_path' ? models.data.filter(({id}) => id === ID)[0] : models.data[0]
    )  
  }, [models])

  console.log('ID is', ID, '\nchosen: ', chosen_model)


  return <div>
      <Head/>
      <RowContainer style={{justifyContent: 'start', alignItems: 'start'}}>
        <PublicPageContent textureIdx={textureIdx} setTextureIdx={(i)=>{setTextureIdx(i); setTextureIdxCashed(i)}} chosen={chosen_model}/>
    </RowContainer>
        
  </div>
}