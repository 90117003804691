// libs
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { AdminPage, LoginPage, AdminPageMod, PublicPage, PublicPageView, PublicPageGreet, AuthPage} from '../../pages' 
import { checkCreditionals } from '../../pages/login-page';
import {ThemeProvider as GitsTheme} from '../../theme_from_git'



const App = () => {

  const defaultRedirect = () => <Navigate to='/auth' />;
  
  
  const [isAuth, setAuth] = useState(checkCreditionals())

  return (
      <GitsTheme>
      <Router>
        <Routes>

          <Route exact path='/'             element={defaultRedirect()} />
          <Route exact path='/public-greet' element={<PublicPageGreet/>}/>
          <Route exact path='/auth'         element={<LoginPage setAuth={setAuth}/>}/>

          <Route exact path='/admin'           element={isAuth ? <AdminPage/> : defaultRedirect()}/>
          <Route exact path='/admin-mod/:id'   element={isAuth ? <AdminPageMod/> : defaultRedirect()}/>

          <Route exact path='/public'          element={isAuth ? <PublicPage/> : defaultRedirect()}/>
          <Route exact path='/public-view/:id' element={isAuth ? <PublicPageView/> : defaultRedirect()}/>
        </Routes>

      </Router>
      </GitsTheme>)
}

export default App;