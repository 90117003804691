import { Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { redirect, useNavigate } from "react-router-dom"
import { ColumnContainer, RowContainer } from "../shitty-lib"


import { ReactComponent as ClosedEye} from './closed_eye.svg'
import { ReactComponent as Eye} from './loginEye.svg'


const background = process.env.PUBLIC_URL + '/media/cubes.svg'
const gradient = 'linear-gradient(106.35deg, #C0D9F2 12.41%, #E6F4FE 51.19%, #FFFFFF 100%)'


const Creditionals = {
    login: 'sip',
    pwd: 'ilovepizza'
}


// also uses in app.js to controll if user is authorized 
export const checkCreditionals = () => {
    const login = localStorage.getItem('login')
    const pwd = localStorage.getItem('pwd')

    if (pwd === null || login === null || pwd === undefined || login === undefined) 
        return false
    
    return pwd === Creditionals.pwd && login === Creditionals.login
}


const dummy = () => {}


export const LoginPage = ({setAuth}) => {

    const handleAuth = () => { 
        setAuth(true)
        redirect('/admin')
    }

    const Title = () => <img style={{marginBottom: '3%', width: '33%'}} src={process.env.PUBLIC_URL + '/media/VR_logo.png'}/>

    const redirect = useNavigate()
    const [isHidden, setHidden] = useState(true)
    const [err, setErr] = useState(false)

    const setLogin = (val) => localStorage.setItem('login', val)
    const setPwd   = (val) => localStorage.setItem('pwd', val)

    // clear cash when enter page
    useEffect( ()=>{
     setLogin('')
     setPwd('')
    },[])

    const BackGround = () => <ColumnContainer 
        style={{ marginBottom: '5vh', alignItems: 'center',
        height: '95vh', width: '90vw',
        backgroundImage: `url(${background})`,
        }}>
        <Title/>

        <ColumnContainer style={{ width: '17%', rowGap: '30px',
        justifyContent: 'start'
        }}>

            <div style={{ display: 'flex', background: 'rgba(122, 185, 232, 0.83)', marginBottom:'-1rem',
            width: '100%', 
            padding: '0.4rem', justifyContent: 'space-between', alignItems: 'center', height: '2.5rem', borderRadius: '10px'}}>
              <img style={{width:'1.5rem', height: '1.2rem', marginRight: '0.4rem'}} src={process.env.PUBLIC_URL + '/media/loginAuth.svg'}/>
              <input
                autoComplete="nope"
                style={{width: '100%', color: 'white', background: 'rgba(0,0,0,0)', border: '0', outline: 'none'}}
                onChange={(e) => setLogin(e.target.value)} 
                placeholder="e-mail" 
                />
            </div>

            <div style={{ display: 'flex', background: 'rgba(122, 185, 232, 0.83)', marginBottom:'-1rem',
            width: '100%', 
            padding: '0.4rem', justifyContent: 'space-between', alignItems: 'center', height: '2.5rem', borderRadius: '10px'}}>
              <img style={{width:'1.5rem', height: '1.2rem', marginRight: '0.4rem'}} src={process.env.PUBLIC_URL + '/media/loginLock.svg'}/>
              <input
                style={{width: '100%', color: 'white', background: 'rgba(0,0,0,0)', border: '0', outline: 'none'}}
                onChange={(e) => setPwd(e.target.value)} 
                placeholder="пароль" 
                type={isHidden ? 'password' : 'text'}
                />
                
                {
                  isHidden ? 
                  <Eye onClick={()=> setHidden(!isHidden)} style={{width:'1.5rem', cursor: 'pointer', marginRight: '0.3rem'}} />
                  : 
                  <ClosedEye onClick={()=> setHidden(!isHidden)} style={{width:'2rem', height: '2.2rem', cursor: 'pointer'}} />
                }
               
            </div>

            {err ? <Typography color='red'> Неправильный логин или пароль </Typography> : null }

            <button 
            onClick={() => checkCreditionals() ? handleAuth() : setErr(true)}
            style={{cursor: 'pointer', background: 'rgba(122, 185, 232, 0.83)', marginTop: err ? '0%' : '18%', border: 0, height: '2.4rem', borderRadius: '10px'}}>
                <Typography color='white'>
                    Войти
                </Typography>
            </button>
        
        </ColumnContainer>
        

    </ColumnContainer>

    return <ColumnContainer style={{ height: '100vh', width: '100vw', zIndex: -100, background: gradient, gap: 0, rowGap: 0, margin: 0}}>
        <BackGround/>
    </ColumnContainer>
}