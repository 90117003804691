import { Checkbox, FormControlLabel, FormGroup, Input, Popper, TextField, Typography } from "@mui/material"
import { ColumnContainer, RowContainer } from "../shitty-lib"
import '../shitty-lib/noScroll.css'
import { redirect, useNavigate } from "react-router-dom"
import { findModels } from "../Utils/requests"
import { useEffect, useRef, useState } from "react"
import { get_sections } from "../config-kostily"
import { was_chosen } from "../shitty-lib/train"
import '../shitty-lib/noScroll.css'
import { key_gen } from "../shitty-lib/hashfunc"

const BarEl = ({model, isLast=false, needRefresh=false}) => {
const redirect = useNavigate()
const dummy = ()=>{}
return <div 
 onClick={()=>{redirect(`/admin-mod/${model.id}`); needRefresh ? window.location.reload() : dummy()}}
 style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', padding: '1rem', maxHeight: '2.5rem', minHeight: '2.5rem', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis',
    border: '1px solid rgba(0, 0, 0, 0.12)', background: 'white', borderRadius: '10px', "&:hover": {background: '#0C61F8'} }}>
 
    <Typography sx={{whiteSpace:'break-spaces'}} > 
    {model.name}</Typography>
</div>}


const Search = ({setModels}) => {
    const [searchTerm, setSearchTerm] = useState({name:'', tags:[]})

    useEffect(() => {
      const delayDebounceFn = setTimeout(async function(){
        console.log(searchTerm)
        // Send Axios request here
        await findModels({request: searchTerm, setModels})
    }, 300)

      return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])


    //const [chosenTags, setChosenTags] = useState([])
    const [isOpen, setOpen] = useState(false)

    const sections = get_sections()
    const addTagSt = (tag)     => setSearchTerm({...searchTerm, tags: [...searchTerm.tags, tag]})
    const delTagSt = (tag_del) => setSearchTerm( {
        ...searchTerm,
        tags: searchTerm.tags.filter(tag => tag.name !== tag_del.name || tag.section !== tag_del.section )

    } )

    const delMultiTag = (TAGS) => {
        let upd = []
        TAGS.map( (tag_del) => (searchTerm.tags.filter(tag => tag.name !== tag_del.name || tag.section !== tag_del.section )
        ).map((filteredTag) => upd.push(filteredTag) ) )
        return upd                      
    }

    // add/del all tags from selected section
    const toggleSection = ({need_section, toggle='+'})=>{
        
        sections.map( ([TAGS, section]) => {
            if (section === need_section)
                toggle === '+' ? setSearchTerm({...searchTerm, tags: TAGS})
                 : setSearchTerm({
                    ...searchTerm,
                    tags: delMultiTag(TAGS)
                }) 
        } )
    }

    const SectionsWindow = () => <ColumnContainer 
    className="noscroll"
    style={{
        alignItems: 'end', justifyContent: 'start', padding: 0,
        border: '1px solid #E1E1E1', borderRadius: '10px',
        marginRight: '5.7rem',
        background: 'white',
        height: '30rem', width: '10rem',
        rowGap: '3px',
        flexWrap: 'nowrap',
        overflow: 'scroll',
        padding: '0.8rem',
        'box-shadow': '0px 2px 20px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.05)'
    }}>
        
        { 
            sections.map( ([tags, section]) => <ColumnContainer key={key_gen(section, 'SECTION:')} className="noscroll"
            style={{overflow: 'scroll', flexWrap: 'nowrap', overflow: 'visible', rowGap: '3px', alignItems: 'start', width: '100%', justifyContent: 'start', padding: 0, margin: 0}}>
            
            <div>
            <Typography textAlign='start' variant="content"
            sx={{cursor: 'pointer'}}
            onClick={()=>toggleSection({need_section: section, toggle: '+'})}
            >  {section} </Typography>   
            </div>
            
            <ColumnContainer key={key_gen(section, 'BRUH:')} className="noscroll" style={{overflow: 'scroll', paddingLeft: '2rem', flexWrap: 'nowrap', overflow: 'visible', rowGap: '0px', alignItems: 'start', justifyContent: 'start', width: '100%',}}>
            {tags.map( tag => <FormControlLabel 
                    key={key_gen(tag, 'FormControl:')}
                    control={ 
                         <Checkbox
                         key={key_gen(tag, 'TAG:')}
                         sx={{padding: 0}}
                         onClick={() => was_chosen(tag, searchTerm.tags) ? delTagSt(tag) : addTagSt(tag)}
                         checked={was_chosen(tag, searchTerm.tags)}/> } 
                    label={tag.name} /> )}
            </ColumnContainer>

            </ColumnContainer>
            )
        }
    
    </ColumnContainer>

    const optionsRef = useRef(null)

    return <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem', marginBottom: '1.2rem', overflow: 'hidden'}}>
    <div style={{ display: 'flex', minHeight:'90%', maxHeight:'90%', maxWidth: '86.5%', minWidth: '86.5%', background: 'white', marginBottom:'-1rem', 
    padding: '0.4rem', justifyContent: 'space-between', alignItems: 'center', border: '1px solid #E1E1E1', height: '2.5rem', borderRadius: '10px'}}>
      <img style={{width:'1.5rem', cursor: 'pointer'}} src={process.env.PUBLIC_URL + '/media/search.svg'}/>
      <input
        style={{width: '65%', background: 'rgba(0,0,0,0)', border: '0', outline: 'none'}}
        value={searchTerm.name}
        onChange={async function(e){
          const name = e.target.value
          setSearchTerm({...searchTerm, name}) // call useEffect to set timeout - not flood with charecters on a server
        }} 
        placeholder="Поиск" 
        />
       <img onClick={()=>setSearchTerm({name:'', tags:[]})} style={{width:'1rem', cursor: 'pointer'}} src={process.env.PUBLIC_URL + '/media/clear.svg'}/>
    </div>

    <img 
        ref={optionsRef} 
        onClick={()=>setOpen(!isOpen)}
        style={{ height:'90%',  cursor: 'pointer'}} 
        src={process.env.PUBLIC_URL + '/media/filter.svg'}/>
    
    <Popper open={isOpen} anchorEl={optionsRef.current}>
        <SectionsWindow/>
    </Popper>
    </div>
}


export const LeftBar = ({models, setModels, needRefresh=false}) => {
    const redirect = useNavigate()

    return <ColumnContainer className="noscroll" style={{
        paddingTop: '2rem', gap: '3px', overflow: 'scroll', paddingLeft: '4px', paddingRight:'4px', flexWrap: 'nowrap',
        justifyContent: 'start', minWidth: '18%', height: '100vh', 
        background: '#F5F9FC', border: '2px solid #E1E1E1'}}>
    

    <RowContainer style={{justifyContent: 'space-between', marginBottom:'0.8rem'}}>
    
    <div
    onClick={()=>redirect('/admin')}
    >
    <Typography variant="title" textAlign='start' style={{cursor: 'pointer', marginLeft: '1rem'}}>
        VR-expo
    </Typography>
    </div>
   
   <img 
    style={{height: '1.5rem', marginRight: '0.5rem', cursor: 'pointer'}}
    src={process.env.PUBLIC_URL + '/media/auth.svg'}
    onClick={()=>redirect('/auth')}
    />    
    </RowContainer>
    
    <Search setModels={setModels}/>


    <ColumnContainer className="noscroll" style={{
        gap: '3px', overflow: 'scroll', flexWrap: 'nowrap', paddingRight:'1rem',
        justifyContent: 'start', minWidth: '18%', height: '78%'}}>
    
    {models.map((model, idx) => <BarEl model={model} needRefresh={needRefresh} isLast={idx===(models.length-1)} />)}
    </ColumnContainer>
    
    </ColumnContainer>
}