import { Alert, Button, Paper, Popover, Popper, Snackbar, TextField, Typography } from "@mui/material"
import { RowContainer, ColumnContainer } from "./containers"
import { Close, Image} from "@mui/icons-material"
import Divider from '@mui/material/Divider';
import { useEffect, useState } from "react"

// using standart names Add, ... => rename naafter import
import { AddModel as Add , AddNewFormat, Auth, Del, Modify, Reg } from "../Utils/requests"
import { refreshData } from "../pages/admin-page"; 
import { FileUploader } from "react-drag-drop-files";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/system";





const handleSubmit = (e) => {
  //refreshPage(500)
  return
}

// fileDataArr structure-related deepCopy
const deepCopyWithBlobs = (fileDataArr) => {
  let almostCoppy = JSON.parse(JSON.stringify(fileDataArr))

  // JSON.stringify doesn`t parse BLOB => setting BLOB poles ourself:
  almostCoppy.map(({pole_name}, i) => {
    if (pole_name === 'file') 
      almostCoppy[i].val = new File([fileDataArr[i].val], fileDataArr[i].val.name, {
        type: fileDataArr[i].val.type,
        lastModified: fileDataArr[i].val.lastModified,
    });  
  })

  return almostCoppy
}

/*
bool isOpen
is_multiple - grant ability to select multiple files

Content - some jsx-content inside modal

// configurating poles:
poles={     
      to_fill: [
      {
        name: '', 
        placeholder: ''
        InputElement: (props) => <El {...props}/> - optional - input by default
      },
      ],
      
      readyData: {}
    }
 
buttons = [[text, color, onClick], ]

formDataArr = [ {formData, needPoles},  ]
*/
export const Modal = ({isOpen, setOpen, poles,
                       is_multiple=true,
                       formDataArr,
                       vanishFormData,
                       hasReadyData=true, 
                       title = 'no title(',
                       Content, 
                       previousVal={},
                       containerStyle={},
                       buttons = [['Ок', 'primary', () => setOpen(false)], 
                                  ['Отмена', 'cancel', () => setOpen(false)]] }) => {
    
    const DefaultInputElement = (props) => <TextField {...props}/> 


    const [Data, setData] = useState(hasReadyData ? poles.readyData : {})    // data for request
    
    // array of key:val to fill the form when click 'OK', ex: readyDataArray = [ {pole_name:'', val:{}}, ]
    const [readyDataArray, setReadyData] = useState([])
  
    
    const addToReadyDataArr = (pole_name, val) => {setReadyData([...readyDataArray, {pole_name, val}])}
    const clearReadyDataArr = () => setReadyData([])
    
    // fires on mount - only once
    useEffect(()=>{
      setData({...previousVal})
    }, [])


    const key_gen = (i, name) => `${name + 'modal' + i}`

    // when files are finaly chosen(there might be CRUD operations with selected files before) - filling array of forms by array of {key,val}
    const fillForm = () => {
      let j = 0 // for dodging i++ when met main file(it adds to pole models and indexing brokes)
      console.log('Data now ready for filling into form:', readyDataArray)
      for (let {pole_name, val} of readyDataArray)
        {
        console.log('[form filler] filling pole: ', pole_name,)
        // filling array of formData
        for (let { formData, needPoles } of formDataArr){
          if (needPoles.includes(pole_name))
            formData.set(pole_name, val)
          else if ( needPoles.includes('files') && pole_name === 'file')  
              {
               const ext = val.name.search(/\./) 
               if (val.name.slice(ext+1, ) === 'obj') 
                 formData.set(`model`, val) 
               else {
                console.log(`mtl[${j}] added, `, val)
                 formData.set(`files[${j}][file]`, val)
                 formData.set(`files[${j}][path]`, "/")
                 j++;
                } 
               
              }    
        }}  
                    
    }


    
  
    return <div>
      <Popover
        open={Boolean(isOpen)}
        anchorEl={isOpen}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: 'center', horizontal: 'center' }}
        sx={{ background: 'rgba(0.1, 0.1, 0.1, 0.1)'}}
        PaperProps={{
          sx: {
            p: '1.5rem',
            pt: '1rem',
            pb: '1rem',
            borderRadius: '11px',
            width: 812,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
            ...containerStyle
          },
        }}
        >
          
       <RowContainer style={{justifyContent: 'space-between', alignItems: 'start', paddingBottom: '1rem'}}> 
            <Typography sx={{textAlign: "center"}} variant="h3">{title}</Typography>
            {setOpen !== false ?
              <Close sx={{width: '40px', height: '40px', cursor: 'pointer'}}
              onClick={() => {setOpen(false); clearReadyDataArr(); if (vanishFormData) vanishFormData() }}/>
            : null}
        </RowContainer>

        
        <ColumnContainer>
            <Divider variant="middle" sx={{marginBottom: '3rem'}}/>
            {hasReadyData ? 
              poles.to_fill.map((pole, i) => {
                const InputElement = pole.InputElement ? pole.InputElement : DefaultInputElement // select type of input 
                return  <InputElement label={pole.placeholder}
                key={key_gen(i, pole.placeholder)}
                defaultValue={previousVal[pole.name]}
                variant="outlined"
                
                files={readyDataArray.filter((el) => el.input_type === 'files')} 
                placeholders={poles.to_fill.filter(el => el.name === 'files').map(pole=>pole.placeholder)}
                onSelectForDragAndDrop={(files) => {
                  
                  if (formDataArr.length > 0) 
                    {
                      formDataArr.map( (x, i) => console.log(`formData[${i}]:`, x))
                      console.log('Changed pole: ', pole.name, '\n**** from Modal | files are:', files)
                      
                      // filling array of formData
                      for (let { needPoles } of formDataArr){
                        if (needPoles.includes(pole.name))
                         if (pole.input_type === 'files'){  
                            is_multiple ?
                            setReadyData(
                              state => {
                              return [...state, ...[...files].map((file) => {
                                return {pole_name: 'file', val: file, input_type:'files'}
                              })]  
                              }
                            ):
                            setReadyData(
                              state => {
                                // 1)reduce {key:val} pairs with key=pole.name  2) add new {key:val}  
                              return [
                                ...state.filter(({pole_name}) => pole_name !== pole.name), 
                                {pole_name: pole.name, val: files[0], input_type:'files'}]
                            }
                            )   
                          }
                      }  
                      console.log('Drag and drop got:', readyDataArray)
                      //console.log('deepcopy of it:', deepCopyWithBlobs(readyDataArray))
                    }

                }}

                onChange={(e) => {
                  
                  e.preventDefault()

                  // !!! TODO - wrap 'if'`s body into handleMultipleForms()
                  if (formDataArr.length > 0) 
                    {
                      formDataArr.map( (x, i) => console.log(`formData[${i}]:`, x))
                      console.log('Changed pole: ', pole.name, '\n**** from Modal | files are:', e.target.files)
                      
                      // filling array of formData
                      for (let { needPoles } of formDataArr){
                        if (needPoles.includes(pole.name))
                         if (['files', 'file'].includes(pole.name))  
                          for (let i = 0; i < e.target.files.length; i++) 
                            addToReadyDataArr('file', e.target.files[i]) // files of model
                         else
                          addToReadyDataArr(pole.name, e.target.value)    // specific poles called pole.name
                      }  

                      //console.log('Data now ready for filling into form:', readyDataArray)
                    }

                  else
                    setData({...Data, [pole.name]: e.target.value})
                }}
                sx={{width: '100%', mb: '1rem'}}/>})
             : null}
             
             {Content ? <Content/> : null}
            <RowContainer style={{width: '100%', gap: '10px', justifyContent: 'end'}}>
              {buttons.map( ([name, color, onClick], i) => <Button variant='contained'
                                                       color={color}
                                                       key={key_gen(i, name)+'btn'}
                                                       onClick={ (e) => {
                                                        if (formDataArr)
                                                          {
                                                            fillForm() 
                                                            onClick(formDataArr)
                                                          }
                                                        else 
                                                          onClick(Data)
                                                        clearReadyDataArr()
                                                      }} 
                                                       sx={{ borderRadius: '10px' }}> 
                                                {name} </Button>)}
            </RowContainer>
          
        </ColumnContainer>
        

    </Popover>

    <Message />
    </div>
  }


export const Message = () => {

  const message = localStorage.getItem('ServerResp') // 'OK', 'WAIT' or 'error message' 

  if (message === null || message === 'undefined')
    localStorage.setItem('ServerResp', 'WAIT')

  console.log(` ||| response from server (stores in cash) ${message}`)

  
  const [stMessage, setStMessage] = useState(message)



  const size = {width: '50%' }
  console.log(` +++ response from server (stores in cash) ${stMessage}`)
  return <Snackbar 
    open={stMessage !== 'WAIT'} 
    style={{...size}}
    onClose={ () => {setStMessage(('WAIT')); localStorage.setItem('ServerResp', 'WAIT')} }
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    autoHideDuration={3000}>

  {
   stMessage !== 'OK' ? <Alert severity="error" style={{ ...size }}>
      {stMessage} 
    </Alert>
   :
   <Alert severity="success" style={{ ...size }}>
     Успешно! 
   </Alert>
  }
</Snackbar>
}


// refreshData() - applies changes on a page after submitting - sets Data 
export const AddModal = ({ endpoint, Content=null, vanishFormData, isOpen, setOpen, is_multiple, poles, formDataArr, refreshData = refreshData, row}) => {

  const buttons = [
    ['Отмена', 'cancel', () => {setOpen(false); vanishFormData()}],
    ['Создать', 'primary', async function(formDataArr) {
      await Add({folderOpts: formDataArr[0].formData, modelFilesOpts: formDataArr[1].formData})
      await refreshData() 
      setOpen(false)
      vanishFormData()
    }], 
  ]

  return <Modal isOpen = {isOpen} setOpen = {() => setOpen(false)}
                title={'Добавление модели'}
                poles={ poles }
                is_multiple={is_multiple}
                formDataArr={ formDataArr }
                buttons = { buttons }

                Content = {Content}
                vanishFormData={vanishFormData}
          />
}


// refreshData() - applies changes on a page after submitting - sets Data 
export const AddNewFormatModal = ({ endpoint, forPreview=false, isOpen, setOpen, is_multiple, poles, formDataArr, refreshData = refreshData}) => {
  
  const path = useLocation().pathname
  const i = path.lastIndexOf('/') + 1
  const id = path.slice(i,)

  if (forPreview) formDataArr[0].formData.append('type', 'preview')

  const buttons = [
    ['Создать', 'primary', async function(formDataArr) {
      await AddNewFormat({ModelOpts: formDataArr[0].formData, id})
      await refreshData() 
      setOpen(false) }], 
  ['Отмена', 'cancel', () => setOpen(false)]
  ]

  
  return <Modal isOpen = {isOpen} setOpen = {() => setOpen(false)}
                title={forPreview ? 'Модель для предпросмотра' : 'Добавление модели в новом формате'}
                poles={ poles }
                is_multiple={is_multiple}
                formDataArr={ formDataArr }
                buttons = { buttons }
          />
}


// row - object with all data for selected table`s row
export const ModifyModal = ({isOpen, formDataArr, setOpen, endpoint, row, poles, refreshData}) => {
  const buttons = [
    ['Применить', 'primary', async function(formDataArr) {await Modify(endpoint, formDataArr[0].formData, row.id); await refreshData(); setOpen(false)}], 
    ['Отмена', 'cancel', () => setOpen(false)]
  ]

  // previousVal = {row.name ? row.name : row.full_name}
  return <Modal isOpen = {isOpen} setOpen = {setOpen}          
                title={`Редактирование`}
                previousVal = {row}
                poles={poles}
                formDataArr={formDataArr}
                buttons = { buttons }
          />
}


// altDel - alternative delete function
export const DelModal = ({isOpen, setOpen, endpoint, row, poles, refreshData, altDel=false}) => {
  const buttons = [
    ['Удалить', 'caution',  async function() { altDel ? await altDel() : await Del(endpoint, row.id); await refreshData(); setOpen(false)}], 
    ['Отмена', 'cancel', () => setOpen(false)]]

  return <Modal hasReadyData={false} isOpen = {isOpen} setOpen = {setOpen}
                title={`Вы, действительно, хотите удалить модель "${row.name}"?`}
                poles= { poles }
                buttons = { buttons }/>
}


export const ContentModal = ({isOpen, setOpen, title='content title', Content}) => {

  return <Modal isOpen = {isOpen} setOpen = {setOpen} Content={Content}
  buttons={[]} title={title} poles={{to_fill: [], readyData: {} }}/>
}



 // auto-fill for poles (in Modal`s format) 
 const fill_poles = (keyValArr=[['placeholder', 'name'], ]) =>{     
  const filled = keyValArr.map((keyVal) => (
    {
      name: keyVal[1], 
      placeholder: keyVal[0],
      InputElement: (props) => <TextField {...props}/>
    }
  ))

  return {
    to_fill: filled,
    readyData: {}
  } 
} 


export const AuthModal = ({}) => {
  const [authOpen, setAuthOpen] = useState(true)
  const [regOpen, setRegOpen] = useState(false)
  
  const switchModal = () => {setAuthOpen(!authOpen); setRegOpen(!regOpen)}

  const buttons = [
    ['Войти', 'primary',  async function() { await Auth() }], 
    ['Регистрация', 'cancel', () => switchModal()]]

  const regButtons = [
    ['Зарегистрироваться', 'primary',  async function() { await Reg() }], 
    ['Вернуться к входу', 'cancel', () => switchModal()]]
  
  
    
  const authPoles = fill_poles( [['Логин', 'login'], ['Пароль', 'password'] ] )
  const regPoles  = fill_poles( [['Логин', 'login'], ['Пароль', 'password'], ['Повторите пароль', 'repeat_pwd'] ] )

  console.log('got authPoles: ', authPoles)
  
  const containerStyle = {
    borderRadius: '5px',
    width: '40rem',
    background: 'rgba(50, 50, 230, 0.61)',
  }
  return <div>
    <Modal hasReadyData={true} isOpen={authOpen} setOpen={false} poles={authPoles}
            containerStyle={containerStyle} title={`Авторизация`} buttons={ buttons }/>

    <Modal hasReadyData={true} isOpen={regOpen} setOpen={false} poles={regPoles}
            containerStyle={containerStyle} title={`Регистрация`} buttons={ regButtons }/>
  </div>
}
