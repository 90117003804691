import { Typography } from "@mui/material"
import { FileUploader } from "react-drag-drop-files"
import { ColumnContainer } from "../shitty-lib"

import '../dragAndDrop.css'

// 'files' are from modal.js: 
// readyDataArray aka files = [ {pole_name:'', val:{}}, ]
export const DrangAndDrop = (props) =>{
    const {files, placeholder='', types=['obj', 'mtl'], style={}} = props  
    console.log('Drag and Drop got, ', props); 
    return <ColumnContainer style={{ rowGap: '0.5rem', alignSelf: 'start', ...style }}>
    
    <Typography textAlign='left'>
     {placeholder}:
    </Typography>

    <FileUploader
      multiple={true}
      handleChange={props.onSelectForDragAndDrop}
      name="file"
      types={types}  
      classes={"dragAndDropBox"}
    />

    <Typography>
        { files.length !== 0 ? files.map((file) => file.val.name) : 'пусто'}
    </Typography>
    
    </ColumnContainer>}