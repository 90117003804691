import { Button, Input, TextField, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { DrangAndDrop } from "../components/dragAndDrop"
import { Head } from "../components/Header"
import { CoolViewer } from "../components/Viewer3D"
import { ColumnContainer, RowContainer } from "../shitty-lib"
import { AddNewFormatModal, ContentModal, DelModal, Message, ModifyModal } from "../shitty-lib/modals"
import { DelFormat, getModelById, getModels, ResetModelTags } from "../Utils/requests"
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'
import { ClearOutlined, DeleteForever } from "@mui/icons-material"
import {hoverStyle} from '../components/InteractiveCard'
import { modify_poles } from "./admin-page"
import { TrainLike, TrainWB, was_chosen } from "../shitty-lib/train"
import { avalibleTags, get_sections } from "../config-kostily"
import { LeftBar } from "../components/LeftBar"
import { ModelDetails } from "../components/modelDetails"




// button that may appear in "Content" of modal 
export const AddButton = ({setOpen=()=>{}, style, color='primary', variant='contained', MUIicon=<AddTwoToneIcon/>, title}) => {
  const defSX = title ? {} : {ml:'2rem', width: '3rem', minWidth: 0, ...style }       // default styling for text/icon buton
  return <Button variant={variant} color={color} sx={{...defSX, ...style }}
      onClick={() => setOpen(true)}>
      {title ? <Typography> {title} </Typography> : MUIicon}    
  </Button>}



// poles to add model in new format
const poles_add={
    to_fill: [
    {
      name: 'type', 
      placeholder: 'Имя формата 3D модели',
      InputElement: (props) => <TextField {...props}/> 
    },
  
    {
      input_type: 'files',
      name: 'zip',
      placeholder: 'Архив с файлами модели',
      InputElement: (props) => <DrangAndDrop {...{...props, types:['zip']}}/>
    },
    ],
    
    readyData: {}
}


const modForm = new FormData()
const addForm = new FormData()
const dummy = new FormData()

// formData to modify name and description
const formDataArrMod = [
    {
      formData: modForm, 
      needPoles: ['name', 'description', 'specs']
    }, 
    {
      formData: dummy, 
      needPoles: ['dummy']
    }, 
]
  
// formData for adding model in new format 
const formDataArrAdd = [
    {
      formData: addForm, 
      needPoles: ['type', 'zip']
    },
]

const ContentArea = ({content, style}) => {

    return <ColumnContainer style={{ border: '1px solid rgba(0,0,0,0.1)', height: '100%', width: '70%', 
    flexWrap: 'nowrap', justifyContent: 'start', padding: '1rem', paddingTop: '0.5rem', ...style}}>
        {content}
    </ColumnContainer>
  }



const Filler = () => <ColumnContainer>
<img 
  style={{width: '35rem', height: '50rem'}}
  src={process.env.PUBLIC_URL + '/media/Millimetrovka.png'}/>
</ColumnContainer>




// works for both modals - for modify - on fly, for create - by cashing chosen tags
export const TagsModalContentEnhanced = ({model, refreshData, id, style}) => {

  // requests to server
  // for modify: (already know id of model)
  async function delTag(tag_del){
    const {tags} = model   
    const upd_tags = tags.filter(tag => tag.name !== tag_del.name || tag.section !== tag_del.section )
    await ResetModelTags({ ...model, tags: upd_tags }, id)
    refreshData()
  }

  async function addTag(tag_add){
    await ResetModelTags({ ...model, tags: [...model.tags, tag_add] }, id)
    refreshData()
  }
 

 const creating_new = !id ? true : false
 const sections_uniq = get_sections()

 const [chosenTags, setChosenTags] = useState(creating_new ? [] : model.tags)
 // state changing
 const addTagSt = (tag)     => setChosenTags([...chosenTags, tag])
 const delTagSt = (tag_del) => setChosenTags( chosenTags.filter(tag => tag.name !== tag_del.name || tag.section !== tag_del.section ) )
 // to create:

 const setTagsCashed = () => localStorage.setItem("Tags_new_model", JSON.stringify(chosenTags))
 useEffect(()=>setTagsCashed(), [chosenTags]) // synchronise update-of-state and update-of-cash 
 
 // need for request after creating model(when id`ll be known)
 const getTagsCashed = () => {
      let cashed = localStorage.getItem("Tags_new_model")
      console.log('cashed tags for new model: ', localStorage.getItem("Tags_new_model"))
      // NEED ERRASE cashed info after return
      return (cashed && cashed !== "undefined") ? JSON.parse(cashed) : [] 
  } 
 

 // onClick for create and modify 
 const onClick = (e, tag) => { 
  const is_chosen = was_chosen(tag, chosenTags) // used to toggle tag
  
  // create:
  if (creating_new)
    is_chosen ? delTagSt(tag) : addTagSt(tag)
  
  // modify:
  else
    is_chosen ? delTag(tag) : addTag(tag)
}

 return <ColumnContainer style={{alignItems: 'start'}}>
 {
  sections_uniq.map( section => <RowContainer style={{alignContent: 'start'}} >
  <TrainWB 
    style={style}
    chosenTags={chosenTags}
    caption={section[1]} nameLinkArr={section[0]} 
    onClick={onClick} />
  </RowContainer>)
 }
 </ColumnContainer>}




const ModPageContent = () => { 
    const redirect = useNavigate()  
    const path = useLocation().pathname
    const i = path.lastIndexOf('/') + 1
    const id = path.slice(i,)

    const [model, setModel] = useState({name: '-', dsecription: '-', formats: []})
    const [modOpen, setModOpen] = useState(false)
    const [delModelOp, setDelModelOp] = useState(false) // modal to delete all files related to model
    const [downloadOp, setDownloadOp] = useState(false)


    const [delName, setDelName] = useState('')
    const [addPreviewOpen, setAddPreviewOpen] = useState(false)

    const [lightColor, setLightColor] = useState(0xFFA348)
    // toggling fullscreen view of model
    const [isFullscreen, setFullScreen] = useState(false)

    const refreshData = () => getModelById({id, setModel})
    useEffect( () => {
        refreshData()
    }, [])
    
    const {description, created_at, updated_at, specs} = model
    const contents = [['Описание', description], ['Характеристики', specs],
     ['Автор', 'no data'], ['Дата создания', created_at], ['Дата последнего редактирования', updated_at] ]
    
   
    
    async function delTag(tag_del){
      const {tags} = model   
      const upd_tags = tags.filter(tag => tag.name !== tag_del.name || tag.section !== tag_del.section )
      await ResetModelTags({ ...model, tags: upd_tags }, id)
      refreshData()
    }
 


    const [tagsOp, setTagsOp] = useState(false)

    const TagsPart = () => <RowContainer style={{alignContent: 'start'}} >
       <TrainLike 
         caption="Тэги модели" nameLinkArr={model.tags} 
         onDelClick={(e, tag) => { delTag(tag)}} />
 
       <AddButton setOpen={setTagsOp}/>
     </RowContainer>


    // ------- part for setting texture
    const [models, setModels] = useState({
      data:[],
      meta: {test: 'there`ll be pagination info'}
    })

    const refreshModels = () => getModels({ setModels: (data) => setModels({...models, ...data})  })
    const message_ref = useRef(null)

    useEffect(()=>{
      refreshModels()
    }, [])

    const setTextureIdxCashed = (x) => localStorage.setItem("TextureIdx", JSON.stringify(x))
    const getTextureIdxCashed = () => {
        let cashed = localStorage.getItem("TextureIdx")
        console.log('cashed texture: ', localStorage.getItem("TextureIdx"))
        return (cashed && cashed !== "undefined") ? JSON.parse(cashed) : 0 
    } 

    const cashedTexture = getTextureIdxCashed()



    // chosen from useState need for rerender (by useEffect)

    const [textureIdx, setTextureIdx] = useState( cashedTexture ? cashedTexture : 0)
    // -------------------------


    

    const InfoContent = <ColumnContainer style={{ alignContent: 'start', height: '100%', justifyContent: 'space-between' }}>

        <ColumnContainer style={{ alignContent: 'start', rowGap: '2rem' }}>
          <RowContainer style={{justifyContent: 'start', marginTop: '5rem', marginRight: '10px', cursor: 'pointer'}}
          onClick={()=>redirect('/admin')}
          >
            <img height='15px' src={process.env.PUBLIC_URL + '/media/l_arrow.svg'}/>
            <Typography variant="title" textAlign='start' style={{ marginLeft: '0.5rem'}}>
              {model.name}
            </Typography>
          </RowContainer>

          {
            contents.slice(0,2).map(([title, content]) => <ColumnContainer style={{alignContent: 'start', rowGap: '0.25rem'}}>
              <Typography textAlign='start' variant="content" sx={{textDecorationLine: 'underline',}}>
                {title} 
              </Typography>

              <Typography textAlign='start' variant="content" style={{ whiteSpace: 'wrap' }}>
                {content}
              </Typography>
            </ColumnContainer> )
          }

          <ColumnContainer style={{display: 'flex', rowGap: '0px', justifyContent: 'start', alignContent: 'start'}}>
            <Typography textAlign='start' variant="content" sx={{textDecorationLine: 'underline',}}>
              Выберите текстуру 
            </Typography>
            
            <div>
              <ModelDetails justTextures={true} textureIdx={textureIdx} setTextureIdx={(i)=>{setTextureIdx(i); setTextureIdxCashed(i)}} chosen={model}/>
            </div>
          </ColumnContainer>

          <div style={{marginTop: '4rem', display: 'flex', alignItems: 'start'}}>
            <TagsPart/>
          </div>

          <div style={{marginTop: '10%'}}>
          {
            contents.slice(3,).map(([title, content]) => <ColumnContainer style={{alignContent: 'start'}}>
            <Typography textAlign='start' variant="content">
              {title}: {content}
            </Typography>
          </ColumnContainer> )
          }
          </div>

      </ColumnContainer>
      
      <RowContainer style={{justifyContent: 'start', gap: '0.7rem'}}>
        <AddButton setOpen={setDownloadOp} title='Форматы'/>
        <AddButton setOpen={setModOpen}    title='Редактировать' color='cancel'/>
        <AddButton setOpen={setDelModelOp} title='Удалить'       color='caution'/>
      </RowContainer>  
      
      
    </ColumnContainer>
    

    const [addOpen, setAddOpen] = useState(false)
    const [delOpen, setDelOpen] = useState(false)       // modal to delete some covering of model(ex: mtl)
    const DownloadModalContent = () => <RowContainer>
      <TrainLike 
        caption="Форматы загрузки" nameLinkArr={model.formats} 
        onDelClick={(e, name) => {setDelName(name); setDelOpen(true)}} />

      <AddButton setOpen={setAddOpen}/>

    </RowContainer>

    
    const has_preview = model.preview && model.preview.model_link

    return <RowContainer style={{ justifyContent: 'start', background: 'white', alignItems: 'start', width: '100%'}}>
    
    <LeftBar setModels={setModels} models={models.data} needRefresh={true}/>
    
    <ContentArea  style={{ width: '35%', height: '97.5vh', alignSelf: 'center', 
    background: 'white', borderRadius: '5px', boxShadow: '0px 4px 20px 0px #0000001A',
    marginLeft: '0.7rem', paddingBottom: '0.8rem', marginBottom: '0.5rem'
     }}
    content={InfoContent}/>
    
    {has_preview ? <CoolViewer WH={{width: 45, height: 50}} color={'E5E5E5'}
        chosen={model} textureIdx={textureIdx} light={lightColor}
        isFullscreen={isFullscreen} setFullScreen={setFullScreen}/> : <ColumnContainer> 
        <Filler/>
        <AddButton style={{ alignSelf: 'center', marginTop: '-3rem' }} setOpen={setAddPreviewOpen}/>
        </ColumnContainer> }

    <ContentModal isOpen={tagsOp} setOpen={setTagsOp} Content={() => TagsModalContentEnhanced({model, refreshData, id})} title='Выберите тэги для модели'/>

    <ContentModal isOpen={downloadOp} setOpen={setDownloadOp} Content={DownloadModalContent} title='Выберите модель для скачивания или загрузите модель в новом формате'/>
    <AddNewFormatModal isOpen={addOpen} setOpen={setAddOpen} is_multiple={false} 
    endpoint='models' refreshData={refreshData} formDataArr={formDataArrAdd} poles={poles_add}/>

    <AddNewFormatModal forPreview={true} isOpen={addPreviewOpen} setOpen={setAddPreviewOpen} is_multiple={false} 
    endpoint='models' refreshData={refreshData} formDataArr={formDataArrAdd} poles={{...poles_add, to_fill: [poles_add.to_fill[1]] }}/>
    
    <DelModal refreshData={refreshData} altDel={() => DelFormat(id, [delName])}   
    isOpen={delOpen} setOpen={setDelOpen} row={{name: delName}}/>
    
    <DelModal refreshData={()=>redirect('/admin')} endpoint='models'
    isOpen={delModelOp} setOpen={setDelModelOp} row={model}/>

    <ModifyModal refreshData={refreshData} poles={modify_poles} endpoint='models' 
    formDataArr={formDataArrMod} isOpen={modOpen} setOpen={setModOpen} row={model}/>

    </RowContainer>
}



export const AdminPageMod = () => {

    return <ColumnContainer>
    <ModPageContent/>
    </ColumnContainer>
}