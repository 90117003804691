
import { TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { Head } from "../components/Header"
import { ModelDetails } from "../components/modelDetails"
import { ModelsBar } from "../components/ModelsBar"
import { OptionsBar } from "../components/OptionsBar"
import { CoolViewer } from "../components/Viewer3D"
import { ColumnContainer, RowContainer } from "../shitty-lib"
import { AddModal } from "../shitty-lib/modals"
import { getModels } from "../Utils/requests"

/*
    From git: https://github.com/kovacsv/Online3DViewerExamples/tree/main/use_as_react_component
        <Basic3DViewer file='../../media/skuller/Skull.obj'/>

    ./media/baseball/baseball_01_4k.gltf
    ./media/showcase/showcase.mtl
    ./media/vase/vase.mtl
    ./media/plant/plant.dae
*/



import { Typography } from "@mui/material"


import { CardsAreaWrapper, DataCardWithImage} from "../components/InteractiveCard"
import { useLocation, useNavigate } from "react-router-dom"





const dummyModels={data:[
  {description:'bruh1', name:'Стойка(dummy)', id: 'dummy46sdiuhfus12'},
  {description:'bruh2', name:'Шкаф(dummy)',   id: 'dummy203902hgdfb2'},
  {description:'bruh3', name:'Стелаж(dummy)', id: 'dummy03295tgfe902'}
],
meta: {test: 'test'}}


// "./media/Millimetrovka.png"
const AdminPageContent = ({models=[]}) => {
  //const [models, setModels] = useState(models)

  const redirect = useNavigate() 
  const path = useLocation().pathname

  return <ColumnContainer style={{ width: '100%', alignItems: 'start', paddingLeft: '2rem', gap: '0px'}}>
    <RowContainer style={{ width: '100%', height: '3rem', alignItems: 'end', justifyContent: 'start',}}>
      <Typography variant="bigTitle"> Каталог моделей </Typography>
      
    </RowContainer>
    
    <CardsAreaWrapper style={{paddingTop: '1rem'}}>
      { 
        models.data.map((model) => <DataCardWithImage card_wh={{w:'19.5rem', h:'19.7rem'}}
          data={{...model, img: model.preview ? model.preview.image_link: './media/Millimetrovka.png'}}
          onClick={ ()=>redirect(`${path}-view/${model.id}`) }
          />
        )}

    </CardsAreaWrapper>
    
    
  </ColumnContainer>
}




export const PublicPage = () => {

  const [models, setModels] = useState({
    data:[{addons_info: [], model_info: 'empty_path'}],
    meta: {test: 'there`ll be pagination info'}
  })


  const setTextureIdxCashed = (x) => localStorage.setItem("TextureIdx", JSON.stringify(x))
  const getTextureIdxCashed = () => {
      let cashed = localStorage.getItem("TextureIdx")
      console.log('cashed texture: ', localStorage.getItem("TextureIdx"))
      return (cashed && cashed !== "undefined") ? JSON.parse(cashed) : 0 
  } 


  useEffect(()=>{
    getModels({ setModels: (data) => setModels({...models, ...data})  })
  }, [])


  // init default state 
  // check if there`s cashed which was chosen previously
  const setChosenCashed = (x) => localStorage.setItem("chosenModel", JSON.stringify(x))
  const getChosenCashed = () => {
      let cashed = localStorage.getItem("chosenModel")
      console.log('cashed Model: ', localStorage.getItem("chosenModel"))
      const readyData = models.data.length > 0 ? models.data[0] : false 
      return (cashed && cashed !== "undefined") ? JSON.parse(cashed) : readyData 
  } 

  const cashedChosen = getChosenCashed() 
  const cashedTexture = getTextureIdxCashed()

  // chosen from useState need for rerender (by useEffect)
  const [chosen, setChosen] = useState( cashedChosen ? cashedChosen : models[0])
  const [textureIdx, setTextureIdx] = useState( cashedTexture ? cashedTexture : 0)

  const [update, setUpdate] = useState(0);
  const refresh = () => setUpdate(update + 1);

  return <div>
      <Head/>
      <RowContainer style={{justifyContent: 'start', alignItems: 'start'}}>

        <AdminPageContent models={models}/>
    </RowContainer>
        
  </div>
}